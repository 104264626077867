import { useState } from "react";

const MUNICIPALITIES = [
  {
    MunicipalityId: "29",
    Name: "CPT - City of Cape Town",
    ProvinceId: "9",
    Region_RegionId: "48",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "30",
    Name: "EC101 - Dr. Beyers Naude",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "31",
    Name: "EC105 - Ndlambe",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "32",
    Name: "EC106 - Sundays River Valley",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "33",
    Name: "EC122 - Mnquma",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "34",
    Name: "EC123 - Great Kei",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "35",
    Name: "EC126 - Ngqushwa",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "36",
    Name: "EC129 - Raymond Mhlaba",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "37",
    Name: "EC131 - Inxuba Yethemba",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "38",
    Name: "EC138 - Sakhisizwe",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "39",
    Name: "EC155 - Nyandeni",
    ProvinceId: "6",
    Region_RegionId: "6",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "40",
    Name: "EC157 - King Sabata Dalindyebo",
    ProvinceId: "6",
    Region_RegionId: "6",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "41",
    Name: "EC441 - Matatiele",
    ProvinceId: "6",
    Region_RegionId: "5",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "42",
    Name: "EC442 - Umzimvubu",
    ProvinceId: "6",
    Region_RegionId: "5",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "43",
    Name: "EC444 - Ntabankulu",
    ProvinceId: "6",
    Region_RegionId: "5",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "44",
    Name: "EKU - Ekurhuleni",
    ProvinceId: "1",
    Region_RegionId: "16",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "45",
    Name: "FS162 - Kopanong",
    ProvinceId: "2",
    Region_RegionId: "13",
    WardCode: "41602000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "46",
    Name: "FS163 - Mohokare",
    ProvinceId: "2",
    Region_RegionId: "13",
    WardCode: "41603000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "47",
    Name: "FS181 - Masilonyana",
    ProvinceId: "2",
    Region_RegionId: "10",
    WardCode: "41801000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "48",
    Name: "FS183 - Tswelopele",
    ProvinceId: "2",
    Region_RegionId: "10",
    WardCode: "41803000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "49",
    Name: "FS192 - Dihlabeng",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41902000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "50",
    Name: "FS193 - Nketoana",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41903000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "51",
    Name: "FS194 - Maluti a Phofung",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41904000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "52",
    Name: "FS195 - Phumelela",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41905000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "53",
    Name: "FS203 - Ngwathe",
    ProvinceId: "2",
    Region_RegionId: "9",
    WardCode: "42003000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "54",
    Name: "FS204 - Metsimaholo",
    ProvinceId: "2",
    Region_RegionId: "9",
    WardCode: "42004000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "55",
    Name: "FS205 - Mafube",
    ProvinceId: "2",
    Region_RegionId: "9",
    WardCode: "42005000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "56",
    Name: "GT423 - Lesedi",
    ProvinceId: "1",
    Region_RegionId: "17",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "57",
    Name: "GT481 - Mogale City",
    ProvinceId: "1",
    Region_RegionId: "18",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "58",
    Name: "GT484 - Merafong City",
    ProvinceId: "1",
    Region_RegionId: "18",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "59",
    Name: "JHB - City of Johannesburg",
    ProvinceId: "1",
    Region_RegionId: "14",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "60",
    Name: "KZN212 - Umdoni",
    ProvinceId: "5",
    Region_RegionId: "24",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "61",
    Name: "KZN216 - Ray Nkonyeni",
    ProvinceId: "5",
    Region_RegionId: "24",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "62",
    Name: "KZN222 - uMngeni",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "63",
    Name: "KZN223 - Mpofana",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "64",
    Name: "KZN224 - Impendle",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "65",
    Name: "KZN225 - Msunduzi",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "66",
    Name: "KZN226 - Mkhambathini",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "67",
    Name: "KZN227 - Richmond",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "68",
    Name: "KZN235 - Okhahlamba",
    ProvinceId: "5",
    Region_RegionId: "23",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "69",
    Name: "KZN238 - Alfred Duma",
    ProvinceId: "5",
    Region_RegionId: "23",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "70",
    Name: "KZN241 - Endumeni",
    ProvinceId: "5",
    Region_RegionId: "25",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "71",
    Name: "KZN252 - Newcastle",
    ProvinceId: "5",
    Region_RegionId: "28",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "72",
    Name: "KZN265 - Nongoma",
    ProvinceId: "5",
    Region_RegionId: "29",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "73",
    Name: "KZN266 - Ulundi",
    ProvinceId: "5",
    Region_RegionId: "29",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "74",
    Name: "KZN271 - Umhlabuyalingana",
    ProvinceId: "5",
    Region_RegionId: "26",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "75",
    Name: "KZN272 - Jozini",
    ProvinceId: "5",
    Region_RegionId: "26",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "76",
    Name: "KZN275 - Mtubatuba",
    ProvinceId: "5",
    Region_RegionId: "26",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "77",
    Name: "KZN282 - uMhlathuze",
    ProvinceId: "5",
    Region_RegionId: "21",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "78",
    Name: "KZN291 - Mandeni",
    ProvinceId: "5",
    Region_RegionId: "27",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "79",
    Name: "KZN293 - Ndwedwe",
    ProvinceId: "5",
    Region_RegionId: "27",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "80",
    Name: "KZN294 - Maphumulo",
    ProvinceId: "5",
    Region_RegionId: "27",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "81",
    Name: "KZN433 - Greater Kokstad",
    ProvinceId: "5",
    Region_RegionId: "22",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "82",
    Name: "KZN434 - Ubuhlebezwe",
    ProvinceId: "5",
    Region_RegionId: "22",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "83",
    Name: "KZN435 - Umzimkhulu",
    ProvinceId: "5",
    Region_RegionId: "22",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "84",
    Name: "KZN436 - Dr. Nkosazana Dlamini Zuma",
    ProvinceId: "5",
    Region_RegionId: "22",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "85",
    Name: "LIM331 - Greater Giyani",
    ProvinceId: "3",
    Region_RegionId: "32",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "86",
    Name: "LIM332 - Greater Letaba",
    ProvinceId: "3",
    Region_RegionId: "32",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "87",
    Name: "LIM333 - Greater Tzaneen",
    ProvinceId: "3",
    Region_RegionId: "32",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "88",
    Name: "LIM341 - Musina",
    ProvinceId: "3",
    Region_RegionId: "33",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "89",
    Name: "LIM344 - Makhado",
    ProvinceId: "3",
    Region_RegionId: "33",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "90",
    Name: "LIM353 - Molemole",
    ProvinceId: "3",
    Region_RegionId: "30",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "91",
    Name: "LIM354 - Polokwane",
    ProvinceId: "3",
    Region_RegionId: "30",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "92",
    Name: "LIM355 - Lepele-Nkumpi",
    ProvinceId: "3",
    Region_RegionId: "30",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "93",
    Name: "LIM362 - Lephalale",
    ProvinceId: "3",
    Region_RegionId: "31",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "94",
    Name: "LIM368 - Modimolle-Mookgophong",
    ProvinceId: "3",
    Region_RegionId: "31",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "95",
    Name: "LIM471 - Ephraim Mogale",
    ProvinceId: "3",
    Region_RegionId: "34",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "96",
    Name: "LIM472 - Elias Motsoaledi",
    ProvinceId: "3",
    Region_RegionId: "34",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "97",
    Name: "LIM473 - Makhuduthamaga",
    ProvinceId: "3",
    Region_RegionId: "34",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "98",
    Name: "MP302 - Msukaligwa",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "99",
    Name: "MP305 - Lekwa",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "100",
    Name: "MP306 - Dipaleseng",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "101",
    Name: "MP312 - Emalahleni",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "102",
    Name: "MP315 - Thembisile Hani",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "103",
    Name: "MP324 - Nkomazi",
    ProvinceId: "7",
    Region_RegionId: "36",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "104",
    Name: "MP325 - Bushbuckridge",
    ProvinceId: "7",
    Region_RegionId: "37",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "105",
    Name: "NW371 - Moretele",
    ProvinceId: "4",
    Region_RegionId: "39",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "106",
    Name: "NW374 - Kgetlengrivier",
    ProvinceId: "4",
    Region_RegionId: "39",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "107",
    Name: "NW375 - Moses Kotane",
    ProvinceId: "4",
    Region_RegionId: "39",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "108",
    Name: "NW381 - Ratlou",
    ProvinceId: "4",
    Region_RegionId: "40",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "109",
    Name: "NW382 - Tswaing",
    ProvinceId: "4",
    Region_RegionId: "40",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "110",
    Name: "NW383 - Mafikeng",
    ProvinceId: "4",
    Region_RegionId: "40",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "111",
    Name: "NW384 - Ditsobotla",
    ProvinceId: "4",
    Region_RegionId: "40",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "112",
    Name: "NW385 - Ramotshere Moiloa",
    ProvinceId: "4",
    Region_RegionId: "40",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "113",
    Name: "NW393 - Mamusa",
    ProvinceId: "4",
    Region_RegionId: "42",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "114",
    Name: "NW394 - Greater Taung",
    ProvinceId: "4",
    Region_RegionId: "42",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "115",
    Name: "NW396 - Lekwa-Teemane",
    ProvinceId: "4",
    Region_RegionId: "42",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "116",
    Name: "NW397 - Ganyesa/Pomfret",
    ProvinceId: "4",
    Region_RegionId: "42",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "117",
    Name: "NW403 - Matlosana",
    ProvinceId: "4",
    Region_RegionId: "41",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "118",
    Name: "NW405 - JB Marks",
    ProvinceId: "4",
    Region_RegionId: "41",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "119",
    Name: "TSH - Tshwane Metro",
    ProvinceId: "1",
    Region_RegionId: "15",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "120",
    Name: "WC011 - Matzikama",
    ProvinceId: "9",
    Region_RegionId: "50",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "121",
    Name: "WC012 - Cederberg",
    ProvinceId: "9",
    Region_RegionId: "50",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "122",
    Name: "WC015 - Swartland",
    ProvinceId: "9",
    Region_RegionId: "50",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "123",
    Name: "WC022 - Witzenberg",
    ProvinceId: "9",
    Region_RegionId: "49",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "124",
    Name: "WC024 - Stellenbosch",
    ProvinceId: "9",
    Region_RegionId: "49",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "125",
    Name: "WC026 - Langeberg",
    ProvinceId: "9",
    Region_RegionId: "49",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "126",
    Name: "WC031 - Theewaterskloof",
    ProvinceId: "9",
    Region_RegionId: "51",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "127",
    Name: "WC032 - Overstrand",
    ProvinceId: "9",
    Region_RegionId: "51",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "128",
    Name: "WC033 - Cape Agulhas",
    ProvinceId: "9",
    Region_RegionId: "51",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "129",
    Name: "WC041 - Kannaland",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "130",
    Name: "WC043 - Mossel Bay",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "131",
    Name: "WC044 - George",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "132",
    Name: "WC045 - Oudtshoorn",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "133",
    Name: "WC047 - Bitou",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "134",
    Name: "WC048 - Knysna",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "135",
    Name: "WC051 - Laingsburg",
    ProvinceId: "9",
    Region_RegionId: "52",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "136",
    Name: "WC053 - Beaufort West",
    ProvinceId: "9",
    Region_RegionId: "52",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "137",
    Name: "BUF - Buffalo City",
    ProvinceId: "6",
    Region_RegionId: "2",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "138",
    Name: "EC102 - Blue Crane Route",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "139",
    Name: "EC104 - Makana",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "140",
    Name: "EC108 - Kouga",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "141",
    Name: "EC109 - Kou-Kamma",
    ProvinceId: "6",
    Region_RegionId: "4",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "142",
    Name: "EC121 - Mbhashe",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "143",
    Name: "EC124 - Amahlathi",
    ProvinceId: "6",
    Region_RegionId: "3",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "144",
    Name: "EC135 - Intsika Yethu",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "145",
    Name: "EC136 - Emalahleni",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "146",
    Name: "EC137 - Engcobo",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "147",
    Name: "EC139 - Enoch Mgijima",
    ProvinceId: "6",
    Region_RegionId: "8",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "148",
    Name: "EC141 - Elundini",
    ProvinceId: "6",
    Region_RegionId: "7",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "149",
    Name: "EC142 - Senqu",
    ProvinceId: "6",
    Region_RegionId: "7",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "150",
    Name: "EC145 - Walter Sisulu",
    ProvinceId: "6",
    Region_RegionId: "7",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "151",
    Name: "EC153 - Ngquza Hill",
    ProvinceId: "6",
    Region_RegionId: "6",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "152",
    Name: "EC154 - Port St Johns",
    ProvinceId: "6",
    Region_RegionId: "6",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "153",
    Name: "EC156 - Mhlontlo",
    ProvinceId: "6",
    Region_RegionId: "6",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "154",
    Name: "EC443 - Winnie Madikizela-Mandela",
    ProvinceId: "6",
    Region_RegionId: "5",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: "DATARQ01",
    Audit_ModifiedOn: "2021-06-26 11:17:55",
  },
  {
    MunicipalityId: "155",
    Name: "ETH - eThekwini",
    ProvinceId: "5",
    Region_RegionId: "19",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "156",
    Name: "FS161 - Letsemeng",
    ProvinceId: "2",
    Region_RegionId: "13",
    WardCode: "41601000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "157",
    Name: "FS182 - Tokologo",
    ProvinceId: "2",
    Region_RegionId: "10",
    WardCode: "41802000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "158",
    Name: "FS184 - Matjhabeng",
    ProvinceId: "2",
    Region_RegionId: "10",
    WardCode: "41804000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "159",
    Name: "FS185 - Nala",
    ProvinceId: "2",
    Region_RegionId: "10",
    WardCode: "41805000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "160",
    Name: "FS191 - Setsoto",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41901000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "161",
    Name: "FS196 - Mantsopa",
    ProvinceId: "2",
    Region_RegionId: "12",
    WardCode: "41906000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "162",
    Name: "FS201 - Moqhaka",
    ProvinceId: "2",
    Region_RegionId: "9",
    WardCode: "42001000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "163",
    Name: "GT421 - Emfuleni",
    ProvinceId: "1",
    Region_RegionId: "17",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "164",
    Name: "GT422 - Midvaal",
    ProvinceId: "1",
    Region_RegionId: "17",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "165",
    Name: "GT485 - Rand West City",
    ProvinceId: "1",
    Region_RegionId: "18",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "166",
    Name: "KZN213 - Umzumbe",
    ProvinceId: "5",
    Region_RegionId: "24",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "167",
    Name: "KZN214 - UMuziwabantu",
    ProvinceId: "5",
    Region_RegionId: "24",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "168",
    Name: "KZN221 - uMshwathi",
    ProvinceId: "5",
    Region_RegionId: "20",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "169",
    Name: "KZN237 - iNkosi Langalibalele",
    ProvinceId: "5",
    Region_RegionId: "23",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "170",
    Name: "KZN242 - Nqutu",
    ProvinceId: "5",
    Region_RegionId: "25",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "171",
    Name: "KZN244 - uMsinga",
    ProvinceId: "5",
    Region_RegionId: "25",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "172",
    Name: "KZN245 - Umvoti",
    ProvinceId: "5",
    Region_RegionId: "25",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "173",
    Name: "KZN253 - eMadlangeni",
    ProvinceId: "5",
    Region_RegionId: "28",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "174",
    Name: "KZN254 - Dannhauser",
    ProvinceId: "5",
    Region_RegionId: "28",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "175",
    Name: "KZN261 - eDumbe",
    ProvinceId: "5",
    Region_RegionId: "29",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "176",
    Name: "KZN262 - UPhongolo",
    ProvinceId: "5",
    Region_RegionId: "29",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "177",
    Name: "KZN263 - Abaqulusi",
    ProvinceId: "5",
    Region_RegionId: "29",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "178",
    Name: "KZN276 - Big Five Hlabisa",
    ProvinceId: "5",
    Region_RegionId: "26",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "179",
    Name: "KZN281 - uMfolozi",
    ProvinceId: "5",
    Region_RegionId: "21",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "180",
    Name: "KZN284 - Umlalazi",
    ProvinceId: "5",
    Region_RegionId: "21",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "181",
    Name: "KZN285 - Mthonjaneni",
    ProvinceId: "5",
    Region_RegionId: "21",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "182",
    Name: "KZN286 - Nkandla",
    ProvinceId: "5",
    Region_RegionId: "21",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "183",
    Name: "KZN292 - KwaDukuza",
    ProvinceId: "5",
    Region_RegionId: "27",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "184",
    Name: "LIM334 - Ba-Phalaborwa",
    ProvinceId: "3",
    Region_RegionId: "32",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "185",
    Name: "LIM335 - Maruleng",
    ProvinceId: "3",
    Region_RegionId: "32",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "186",
    Name: "LIM343 - Thulamela",
    ProvinceId: "3",
    Region_RegionId: "33",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "187",
    Name: "LIM345 - Collins Chabane",
    ProvinceId: "3",
    Region_RegionId: "33",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "188",
    Name: "LIM351 - Blouberg",
    ProvinceId: "3",
    Region_RegionId: "30",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "189",
    Name: "LIM361 - Thabazimbi",
    ProvinceId: "3",
    Region_RegionId: "31",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "190",
    Name: "LIM366 - Bela-Bela",
    ProvinceId: "3",
    Region_RegionId: "31",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "191",
    Name: "LIM367 - Mogalakwena",
    ProvinceId: "3",
    Region_RegionId: "31",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "192",
    Name: "LIM476 - Fetakgomo Tubatse",
    ProvinceId: "3",
    Region_RegionId: "34",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "193",
    Name: "MAN - Mangaung",
    ProvinceId: "2",
    Region_RegionId: "11",
    WardCode: "49400000",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "194",
    Name: "MP301 - Chief Albert Luthuli",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "195",
    Name: "MP303 - Mkhondo",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "196",
    Name: "MP304 - Dr Pixley Ka Isaka Seme",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "197",
    Name: "MP307 - Govan Mbeki",
    ProvinceId: "7",
    Region_RegionId: "38",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "198",
    Name: "MP311 - Victor Khanye",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "199",
    Name: "MP313 - Steve Tshwete",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "200",
    Name: "MP314 - Emakhazeni",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "201",
    Name: "MP316 - Dr JS Moroka",
    ProvinceId: "7",
    Region_RegionId: "35",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "202",
    Name: "MP321 - Thaba Chweu",
    ProvinceId: "7",
    Region_RegionId: "37",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "203",
    Name: "MP326 - City of Mbombela",
    ProvinceId: "7",
    Region_RegionId: "36",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "204",
    Name: "NMA - Nelson Mandela Bay",
    ProvinceId: "6",
    Region_RegionId: "1",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "205",
    Name: "NW372 - Madibeng",
    ProvinceId: "4",
    Region_RegionId: "39",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "206",
    Name: "NW373 - Rustenburg",
    ProvinceId: "4",
    Region_RegionId: "39",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "207",
    Name: "NW392 - Naledi",
    ProvinceId: "4",
    Region_RegionId: "42",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "208",
    Name: "NW404 - Maquassi Hills",
    ProvinceId: "4",
    Region_RegionId: "41",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "209",
    Name: "WC013 - Bergrivier",
    ProvinceId: "9",
    Region_RegionId: "50",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "210",
    Name: "WC014 - Saldanha Bay",
    ProvinceId: "9",
    Region_RegionId: "50",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "211",
    Name: "WC023 - Drakenstein",
    ProvinceId: "9",
    Region_RegionId: "49",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "212",
    Name: "WC025 - Breede Valley",
    ProvinceId: "9",
    Region_RegionId: "49",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "213",
    Name: "WC034 - Swellendam",
    ProvinceId: "9",
    Region_RegionId: "51",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "214",
    Name: "WC042 - Hessequa",
    ProvinceId: "9",
    Region_RegionId: "53",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "215",
    Name: "WC052 - Prince Albert",
    ProvinceId: "9",
    Region_RegionId: "52",
    WardCode: "0",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "216",
    Name: "NC061 - RICHTERSVELD",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "217",
    Name: "NC061 - RICHTERSVELD",
    ProvinceId: "8",
    Region_RegionId: "0",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "218",
    Name: "NC062 - NAMA KHOI",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "219",
    Name: "NC064 - KAMIESBERG",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "220",
    Name: "NC065 - HANTAM",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "221",
    Name: "NC066 - KAROO HOOGLAND",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "222",
    Name: "NC067 - KHÃ¢I-MA",
    ProvinceId: "8",
    Region_RegionId: "47",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "223",
    Name: "NC071 - UBUNTU",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "224",
    Name: "NC072 - UMSOBOMVU",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "225",
    Name: "NC073 - EMTHANJENI",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "226",
    Name: "NC074 - KAREEBERG",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "227",
    Name: "NC075 - RENOSTERBERG",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "228",
    Name: "NC076 - THEMBELIHLE",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "229",
    Name: "NC077 - SIYATHEMBA",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "230",
    Name: "NC078 - SIYANCUMA",
    ProvinceId: "8",
    Region_RegionId: "45",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "231",
    Name: "NC082 - KAI !GARIB",
    ProvinceId: "8",
    Region_RegionId: "46",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "232",
    Name: "NC084 - !KHEIS",
    ProvinceId: "8",
    Region_RegionId: "46",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "233",
    Name: "NC085 - TSANTSABANE",
    ProvinceId: "8",
    Region_RegionId: "46",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "234",
    Name: "NC086 - KGATELOPELE",
    ProvinceId: "8",
    Region_RegionId: "46",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "235",
    Name: "NC087 - Dawid Kruiper",
    ProvinceId: "8",
    Region_RegionId: "46",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "236",
    Name: "NC091 - Sol Plaatje",
    ProvinceId: "8",
    Region_RegionId: "44",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "237",
    Name: "NC092 - Dikgatlong",
    ProvinceId: "8",
    Region_RegionId: "44",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "238",
    Name: "NC093 - Magareng",
    ProvinceId: "8",
    Region_RegionId: "44",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "239",
    Name: "NC094 - Phokwane",
    ProvinceId: "8",
    Region_RegionId: "44",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "240",
    Name: "NC451 - Joe Morolong",
    ProvinceId: "8",
    Region_RegionId: "43",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "241",
    Name: "NC452 - GA-SEGONYANA",
    ProvinceId: "8",
    Region_RegionId: "43",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    MunicipalityId: "242",
    Name: "NC453 - GAMAGARA",
    ProvinceId: "8",
    Region_RegionId: "43",
    WardCode: "0",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
];

const convertToMap = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.MunicipalityId] = item.Name.trim();
    });
  }
  return mapInit;
};

const convertToID = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.Name.toLocaleLowerCase().trim()] = item.MunicipalityId;
    });
  }
  return mapInit;
};

export const getMunicipalities = () => {
  const municipalities = MUNICIPALITIES;
  const municipalityMap = convertToMap(MUNICIPALITIES);
  const municipalityIdMap = convertToID(MUNICIPALITIES);

  return { municipalities, municipalityMap, municipalityIdMap };
};

export default MUNICIPALITIES;
