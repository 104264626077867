import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import HeaderLinksAuth from "../../components/Header/HeaderLinksAuth.js";
import * as authActions from "../../store/actions/authActions";

import { AccountCircle } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import { NavLink, Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [confirmed, setConfirmed] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const classes = useStyles();
  const { ...rest } = props;
  const auth = getAuth();

  console.log("Status signin: ", auth);

  const dispatch = useDispatch();

  const authState = useSelector((state) => state);
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const user = { email, password };

  const inputChangeE = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeP = (value) => {
    setPassword(value.target.value);
  };

  const togglePassword = () => {
    console.log("toggle password");
    passwordType === "text"
      ? setPasswordType("password")
      : setPasswordType("text");
  };

  const authHandler = async () => {
    let action;

    action = authActions.signin(user, "student");
    // setError(null);
    setIsLoading(true);
    try {
      console.log("starting auth process");
      dispatch(action);
    } catch (err) {
      console.log("THERE WAS AN ERROR: ", err);
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setError(authState.auth.authError);
  }, [authState.auth.authError]);

  if (authState.auth.studentLoggedIn) return <Navigate to="/targets" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.container}
        style={
          {
            // backgroundImage: "url(" + background + ")",
            // backgroundSize: "cover",
            // backgroundPosition: "top center",
          }
        }
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form}>
                <CardHeader
                  style={{
                    backgroundColor: "white",
                    borderWidth: 2,
                    borderColor: "black",
                  }}
                  className={classes.cardHeader}
                >
                  <h3>Login</h3>
                  <h4 style={{ color: "black" }}>
                    Please fill out the following fields to login:
                  </h4>
                </CardHeader>

                <CardBody>
                  <CustomInput
                    labelText="Username..."
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (text) => {
                        inputChangeE(text);
                      },
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircle className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Password..."
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (text) => {
                        inputChangeP(text);
                      },
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={() => {
                              togglePassword();
                            }}
                          >
                            <VisibilityIcon />
                          </div>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <NavLink
                      style={{
                        color: "white",
                        textDecorationLine: "none",
                      }}
                      to={"/targets"}
                    > */}
                    <p style={{ color: "red", padding: 0, margin: 0 }}>
                      {error}
                    </p>
                    <Button
                      simple
                      style={{ color: "white", backgroundColor: "#a50100" }}
                      size="lg"
                      disabled={!confirmed}
                      onClick={authHandler}
                    >
                      Login
                    </Button>
                    {/* </NavLink> */}
                    <Button
                      style={{ color: "black" }}
                      onClick={() => {
                        if (email.length < 3) {
                          alert(
                            "Enter an email and a reset link will be sent, it might be in spam"
                          );
                          return;
                        }
                        dispatch(authActions.forgotPassword(email));
                      }}
                    >
                      Forgot Password
                    </Button>
                  </div>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div style={{ color: "black", width: "100%", fontSize: 12 }}>
              <h3>Disclaimer:</h3>
              <ul>
                <li>
                  By logging on to this membership management database of the
                  EFF, I understand my obligation and agree to capture all
                  member details accurately and in accordance with each
                  application for membership form I receive to capture on the
                  database.
                </li>
                <li>
                  I also agree that I will not tamper with, or falsify any
                  member's information on this database.
                </li>
                <li>
                  I am aware that this system keeps an audit log of all data
                  changes performed on the database.
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  value={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                />
                <p>I Confirm</p>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
