import db from "./firebase";
import {
  onSnapshot,
  collection,
  setDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import moment from "moment";
import { idCheck } from "./validation";
import { child, get, getDatabase, ref } from "firebase/database";
import { registeredVoterCheck } from "./checks";
import { MUNICIPALITIES, PROVINCES, REGIONS, WARDS } from "../assets/data";
import { LANGUAGES } from "../assets/data/Languages";
import { RACES } from "../assets/data/Races";

export const getIecData = async () => {
  const rtdb = getDatabase();
  const tokenRef = ref(rtdb);

  const iecData = get(child(tokenRef, `iec`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return iecData;
};

export const getSubscription = (dateJoined, lastPayment) => {
  //if last payment is within 2 years of date joined it is a new sub else renewal

  return moment(lastPayment).format() <
    moment(dateJoined).add(2, "years").format()
    ? "New"
    : moment(lastPayment).format() > moment(dateJoined).add(2, "years").format()
    ? "Renewal"
    : "INVALID";
};

export const getNameAndSurname = (docData) => {
  const currentName = docData.name;
  const currentSurname = docData.surname;
  const currentFirstName = docData.firstName;

  // only surname

  if (
    currentSurname &&
    currentSurname.trim().length > 1 &&
    !currentName &&
    !currentFirstName
  ) {
    const words = currentSurname.split(" ");
    return { name: words.slice(0, -1).join(" "), surname: words.pop(-1) };
  } else if (
    currentSurname &&
    currentSurname.trim().length > 1 &&
    ((currentName && currentName.trim().length < 1) ||
      (currentFirstName && currentFirstName.trim().length < 1))
  ) {
    const words = currentSurname.split(" ");
    return { name: words.slice(0, -1).join(" "), surname: words.pop(-1) };
  }

  // only name
  else if (
    currentName &&
    currentName.trim().length > 1 &&
    !currentSurname &&
    !currentFirstName
  ) {
    const words = currentName.split(" ");
    return { name: words.slice(0, -1).join(" "), surname: words.pop(-1) };
  } else if (
    currentName &&
    currentName.trim().length > 1 &&
    ((currentSurname && currentSurname.trim().length < 1) ||
      (currentFirstName && currentFirstName.trim().length < 1))
  ) {
    const words = currentName.split(" ");
    return { name: words.slice(0, -1).join(" "), surname: words.pop(-1) };
  }

  //surname and firstname
  else if (
    currentSurname &&
    currentSurname.trim().length > 1 &&
    currentFirstName &&
    currentFirstName.trim().length > 1
  ) {
    return { name: currentFirstName, surname: currentSurname };
  }

  //surname and name
  else if (
    currentSurname &&
    currentSurname.trim().length > 1 &&
    currentName &&
    currentName.trim().length > 1
  ) {
    return { name: currentName, surname: currentSurname };
  } else return docData;
};

export const getLocationInfo = (ward) => {
  const wardData = WARDS.find((item) => item.WardName === ward);
  if (wardData === undefined) {
    return {
      provinceName: " ",
      regionName: " ",
      municipalityName: " ",
      successful: false,
    };
  }
  const municipalityData = MUNICIPALITIES.find(
    (item) => item.MunicipalityId === wardData.MunicipalityId
  );
  const regionData = REGIONS.find(
    (item) => item.RegionId === municipalityData.Region_RegionId
  );
  const provinceData = PROVINCES.find(
    (item) => item.ProvinceId === regionData.ProvinceId
  );

  return {
    provinceName: provinceData.Name,
    regionName: regionData.Name,
    municipalityName: municipalityData.Name,
    successful: true,
  };
};

export const getRace = (race) => {
  let formattedRace = race.trim().toUpperCase();

  if (formattedRace === "1" || formattedRace === "AFRICAN") {
    formattedRace = "BLACK";
  }
  if (formattedRace === "2") {
    formattedRace = "COLOURED";
  }
  if (formattedRace === "3") {
    formattedRace = "INDIAN";
  }
  if (formattedRace === "4") {
    formattedRace = "WHITE";
  }

  return formattedRace;
};

export const getLanguage = (language) => {
  let formattedLanguage = language.trim().toUpperCase();

  if (
    formattedLanguage === "SWATI" ||
    formattedLanguage === "SESWATI" ||
    formattedLanguage === "SISWAZI" ||
    formattedLanguage === "7"
  ) {
    formattedLanguage = "SWAZI";
  }

  if (formattedLanguage === "ISIZULU" || formattedLanguage === "12") {
    formattedLanguage = "ZULU";
  }

  if (formattedLanguage === "ISIXHOSA" || formattedLanguage === "11") {
    formattedLanguage = "XHOSA";
  }
  if (formattedLanguage === "ISNDEBELE" || formattedLanguage === "3") {
    formattedLanguage = "NDEBELE";
  }

  if (formattedLanguage === "XITSONGA" || formattedLanguage === "8") {
    formattedLanguage = "TSONGA";
  }

  if (formattedLanguage === "SETSWANA" || formattedLanguage === "9") {
    formattedLanguage = "TSWANA";
  }

  if (
    formattedLanguage === "SEPEDI" ||
    formattedLanguage === "SESOTHO SA LABOA" ||
    formattedLanguage === "PEDI" ||
    formattedLanguage === "SESOTHO" ||
    formattedLanguage === "5"
  ) {
    formattedLanguage = "NORTHERN SOTHO";
  }

  if (
    formattedLanguage === "SISOTHO" ||
    formattedLanguage === "SUTO" ||
    formattedLanguage === "SOUTO" ||
    formattedLanguage === "SUTHU" ||
    formattedLanguage === "6"
  ) {
    formattedLanguage = "SOTHO";
  }

  if (
    formattedLanguage === "LUVENDA" ||
    formattedLanguage === "TSHIVENDA" ||
    formattedLanguage === "10"
  ) {
    formattedLanguage = "VENDA";
  }

  if (formattedLanguage === "1") {
    formattedLanguage = "AFRIKAANS";
  }
  if (formattedLanguage === "2") {
    formattedLanguage = "ENGLISH";
  }
  if (formattedLanguage === "1") {
    formattedLanguage = "AFRIKAANS";
  }
  if (formattedLanguage === "1") {
    formattedLanguage = "AFRIKAANS";
  }

  return formattedLanguage;
};

export const getMembers = async () => {
  const members = onSnapshot(collection(db, "members"));
  return members;
};

export const getMember = async (idNumber) => {
  const docRef = doc(db, "members", idNumber);
  //check if registered to vote
  // renewIecToken();

  const member = await getDoc(docRef);

  return member;
};

export const createMember = async ({
  province,
  region,
  municipality,
  ward,
  votingStation,
  name,
  surname,
  idNumber,
  age,
  gender,
  citizenship,
  race,
  language,
  resAddress,
  cellNumber,
  landlineNumber,
  email,
  occupation,
  qualification,
  dateJoined,
  lastPayment,
  subscription,
  membershipAmount,
  token,
}) => {
  const collectionRef = collection(db, "members");

  const formattedLanguage = getLanguage(language);
  const formattedRace = getRace(race);

  //check if ID is valid

  if (!idCheck(idNumber)) {
    // alert(`ID number ${idNumber} is invalid`);
    return { reason: "The id number is invalid" };
  }

  if (!/^\d+$/.test(ward)) {
    // alert(`ID number ${idNumber} is invalid`);
    return { reason: "The ward is invalid" };
  }

  if (!LANGUAGES.includes(formattedLanguage)) {
    return { reason: "The language is invalid" };
  }

  if (!RACES.includes(formattedRace)) {
    return { reason: "The race is invalid" };
  }

  if (moment(dateJoined).format() === "Invalid date") {
    return { reason: "The date joined is invalid" };
  }

  if (moment(lastPayment).format() === "Invalid date") {
    return { reason: "The last payment date is invalid" };
  }

  const docRef = doc(db, "members", idNumber);

  let identity = null;

  const dataQuery = query(collectionRef, where("idNumber", "==", idNumber));
  console.log("here");
  const dataQueryDocs = await getDocs(dataQuery);

  if (dataQueryDocs.docs.length > 0) {
    // alert(`A member with ID number ${idNumber} already exists!`);
    return {
      reason: "The id number is already a member",
    };
  }

  // const regCheck = await registeredVoterCheck(idNumber, token);
  // if (!regCheck.check) {
  //   if (regCheck.error) {
  //     return { reason: "ERROR (IEC registration check)" };
  //   } else {
  //     return {
  //       reason: "The id number is not registered",
  //     };
  //   }
  // }

  const { municipalityName, regionName, provinceName } = getLocationInfo(ward);

  const payload = {
    province: provinceName ? provinceName : " ",
    region: regionName ? regionName : " ",
    municipality: municipalityName ? municipalityName : " ",
    ward: ward ? ward : " ",
    votingStation: votingStation ? votingStation : " ",
    name,
    surname,
    idNumber,
    age: age ? age : " ",
    gender: gender ? gender : " ",
    citizenship: citizenship ? citizenship : " ",
    race: formattedRace ? formattedRace : " ",
    language: formattedLanguage ? formattedLanguage : " ",
    residentialAddress: resAddress ? resAddress : " ",
    cellNumber: cellNumber ? cellNumber : " ",
    landlineNumber: landlineNumber ? landlineNumber : " ",
    email: email ? email : " ",
    occupation,
    qualification: qualification ? qualification : " ",
    dateJoined: dateJoined ? moment(dateJoined).format() : " ",
    lastPayment: lastPayment ? moment(lastPayment).format() : " ",
    subscription: getSubscription(
      moment(dateJoined).format(),
      moment(lastPayment).format()
    ),
    membershipAmount,
    expiryDate: moment(lastPayment).add(2, "years").format(),
    invalid: false,
  };

  setDoc(docRef, payload)
    .then(() => {})
    .catch((err) => {
      console.log("err at firestore context");
      return {
        reason: `ERROR`,
      };
    });

  return {
    reason: "Successful",
    province: provinceName ? provinceName : " ",
    region: regionName ? regionName : " ",
    municipality: municipalityName ? municipalityName : " ",
  };
};

export const renewMember = async (idNumber, updatedPaymentDate, fix) => {
  const CollectionRef = collection(db, "members");
  if (idNumber === undefined || idNumber === "" || idNumber.length < 2) {
    return "The id number is invalid";
  }

  const dataQuery = query(CollectionRef, where("idNumber", "==", idNumber));
  const dataQuery2 = query(
    CollectionRef,
    where("rsaIdentityNo", "==", idNumber)
  );

  const document = await getDocs(dataQuery);
  const document2 = await getDocs(dataQuery2);

  if (
    typeof document.docs[0] === "undefined" &&
    typeof document2.docs[0] === "undefined"
  ) {
    return "The id number is not a member";
  }

  const currentdate = new Date();

  if (document2.docs[0]) {
    if (
      moment(document2.docs[0].data().lastMembershipPaymentReceived).format() >
        moment(currentdate).subtract(2, "years").format() &&
      !fix
    ) {
      return "The id number is already in good-standing";
    }

    const docRef = doc(db, "members", document2.docs[0].id);

    const value = setDoc(
      docRef,
      {
        lastPayment: moment(updatedPaymentDate).format(),
        expiryDate: moment(updatedPaymentDate).add(2, "years").format(),
      },
      { merge: true }
    )
      .then(() => {
        return "Successful";
      })
      .catch((error) => {
        console.log("Error: ", error);
        return "ERROR";
      });

    return value;
  }

  if (
    moment(document.docs[0].data().expiryDate).format() >
      moment(currentdate).format() &&
    !fix
  ) {
    return "The id number is already in good-standing";
  }

  const docRef = doc(db, "members", document.docs[0].id);

  await setDoc(
    docRef,
    {
      lastPayment: moment(updatedPaymentDate).format(),
      expiryDate: moment(updatedPaymentDate).add(2, "years").format(),
    },
    { merge: true }
  )
    .then(() => {
      return "Successful";
    })
    .catch((error) => {
      console.log("Error: ", error);
      return "ERROR";
    });

  return "Successful";
};

export const editMember = async ({
  id,
  province,
  region,
  municipality,
  ward,
  votingStation,
  name,
  surname,
  idNumber,
  age,
  gender,
  citizenship,
  race,
  language,
  resAddress,
  cellNumber,
  landlineNumber,
  email,
  occupation,
  qualification,
  dateJoined,
  lastPayment,
  subscription,
  membershipAmount,
}) => {
  const ref = collection(db, "members");

  const formattedLanguage = getLanguage(language);
  const formattedRace = getRace(race);

  if (!idCheck(idNumber)) {
    // alert(`ID number ${idNumber} is invalid`);
    return "INVALID: The id number is invalid";
  }

  if (!LANGUAGES.includes(formattedLanguage)) {
    return { reason: "The language is invalid" };
  }

  if (!RACES.includes(formattedRace)) {
    return { reason: "The race is invalid" };
  }

  let identity = id;
  if (identity === null) {
    const dataQuery1 = query(ref, where("idNumber", "==", idNumber));
    await getDocs(dataQuery1)
      .then((data) => {
        if (data.docs.length > 0) {
          identity = data.docs[0].id;
        } else {
          const dataQuery2 = query(ref, where("rsaIdentityNo", "==", idNumber));
          getDocs(dataQuery2)
            .then((results) => {
              identity = results.docs[0].id;
            })
            .catch((err) => {
              return "INVALID: The id number does not exist";
            });
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }

  if (!/^\d+$/.test(ward)) {
    // alert(`ID number ${idNumber} is invalid`);
    return { reason: "The ward is invalid" };
  }

  if (identity === null) {
    return "INVALID: The id number is invalid";
  }

  const { municipalityName, regionName, provinceName } = getLocationInfo(ward);

  const docRef = doc(db, "members", identity);

  setDoc(
    docRef,
    {
      province: provinceName ? provinceName : " ",
      region: regionName ? regionName : " ",
      municipality: municipalityName ? municipalityName : " ",
      ward: ward ? ward : " ",
      votingStation: votingStation ? votingStation : " ",
      name: name ? name : " ",
      surname: surname ? surname : " ",
      idNumber: idNumber ? idNumber : " ",
      age: age ? age : " ",
      gender: gender ? gender : " ",
      citizenship: citizenship ? citizenship : " ",
      race: formattedRace ? formattedRace : " ",
      language: formattedLanguage ? formattedLanguage : " ",
      residentialAdress: resAddress ? resAddress : " ",
      cellNumber: cellNumber ? cellNumber : " ",
      landlineNumber: landlineNumber ? landlineNumber : " ",
      email: email ? email : " ",
      occupation: occupation ? occupation : " ",
      qualification: qualification ? qualification : " ",
      dateJoined: dateJoined ? moment(dateJoined).format() : " ",
      lastPayment: lastPayment ? moment(lastPayment).format() : " ",
      subscription: subscription ? subscription : " ",
      membershipAmount: membershipAmount ? membershipAmount : " ",
      expiryDate: moment(lastPayment).add(2, "years").format(),
      invalid: false,
    },
    { merge: true }
  )
    .then(() => {})
    .catch((err) => {
      console.log("err at firestore context");
      return {
        reason: `ERROR`,
      };
    });

  return { reason: "Successful" };
};

export const deleteMember = async (idNumber) => {
  const CollectionRef = collection(db, "members");

  const dataQuery = query(CollectionRef, where("idNumber", "==", idNumber));
  const document = await getDocs(dataQuery);

  if (typeof document.docs[0] === "undefined") {
    return "The id number is not a member";
  }

  await deleteDoc(doc(db, "members", document.docs[0].id));
};
