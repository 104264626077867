import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import db from "../../api/firebase";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

export default function UnwantedStatsPage(props) {
  const [members, setMembers] = useState();
  const { ...rest } = props;
  const auth = getAuth();

  const columns = [
    {
      field: "province",
      headerName: "Province",
      width: 150,
    },
    {
      field: "region",
      headerName: "Region",
      width: 150,
    },
    {
      field: "municipality",
      headerName: "Municipality",
      width: 150,
    },
    {
      field: "ward",
      headerName: "Ward",
      width: 150,
    },
    {
      field: "votingStation",
      headerName: "Voting Station",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },
    { field: "id", headerName: "RSA ID", width: 90 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "dateJoined",
      headerName: "Date Joined",
      width: 150,
    },
    {
      field: "lastPayment",
      headerName: "Pmnt Received",
      width: 150,
    },
    {
      field: "membershipAmount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "expires",
      headerName: "Expire On",
      width: 150,
    },
  ];

  useEffect(() => {
    onSnapshot(collection(db, "members"), (snapshot) =>
      setMembers(
        snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          expires: doc.data().dateJoined,
        }))
      )
    );
  }, []);

  const authState = useSelector((state) => state);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
        }}
        justify="center"
      >
        <h1>Unwanted Stats</h1>
        <DataGrid
          rows={members}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[5]}
          //   checkboxSelection
          disableSelectionOnClick
        />
      </GridContainer>
    </div>
  );
}
